import React from 'react'
import { Link } from 'react-router-dom';


import './index.css';
import './App.css'
import './Account.css'
import './Developer.css'

import { useNavigate } from 'react-router-dom'

function Developer() {

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    const navigate = useNavigate()

    return (

        <div className="App">
            <header className="App-header">

                <a className='header-logo-container' href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                <div className="BoozeBook"><h1>BoozeBook</h1></div>

            </header>
            <main className='dev-mains'>

                <div className='header'>
                    <h2>Developers keuze</h2>
                    <p>Hieronder vindt je de voorkeur van elke developer op gebied van drank, café's, etc.</p>
                </div>

                <div className='developers'>  

                    <ul>

                    <li>
                        <div className='developer'>
                            <h3>Lenco De Moerlooze</h3>
                            <img src='https://i.imgur.com/u8vZQFb.png' alt='Pasfoto van Lenco De Moerlooze'></img>
                            <p>Mijn voorkeur op vlak van drank ligt bij de bieren (Hetgeen waar ). Meer betreffende de doodgewone pils. Mijn favoriete pils merken zijn Jupiler
                            en Stella. Tegen een Maes of Primus zal ik ook wel geen nee zeggen. Mensen die Heineken of Stella drinken en lekker vinden mogen voor mij
                            branden in de hel.</p>
                        </div>
                    </li>

                    <li>
                        <div className='developer'>
                            <h3>Eytan Nurflus</h3>
                        </div>
                    </li> 

                    <li>
                        <div className='developer'>
                            <h3>Jannes De Cock</h3>
                            <img src='https://i.imgur.com/6e2xm8w.png' alt='Foto van Jannes De Cock in scoutsuniform'></img>
                        </div>
                    </li>

                    <li>
                        <div className='developer'>
                            <h3>Kilian Kyndt de maagd hahahaha skill issue</h3>
                            <img src='https://i.imgur.com/7xI50F7.pn' alt='Foto van Kilian die slaapt'></img>
                        </div>
                    </li>    
                </ul>
                </div>

            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default Developer;