import { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

function Verify() {

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    const [token, setToken] = useState('')
    const [error, setError] = useState('')

    const navigate = useNavigate()


    function onLoad() {
        let string = window.location.search
        setToken(string.substring(1))
        axios.post(url.concat('/verify'), {
            token: token
        }).then((data) => {
            console.log(data)
            if (data.data === "Registerd") {
                navigate('/login/')
            } else if (data.data === "Wrong token") {
                setError('Wrong token')
            }

        })
    }

    useEffect(() => {
        onLoad()
    })

    return (
        <div className="App">
            <header className="App-header">

                <a href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                <div className="BoozeBook"><h1>BoozeBook</h1></div>

            </header>
            <main>
                <label style={{color: "red", fontSize: "4vh"}}>{error}</label>
            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    )

}

export default Verify