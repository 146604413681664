import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react'


import './index.css';
import './App.css'
import './Bier.css'

import { useNavigate, useParams } from 'react-router-dom'

function Bieren() {

    const [isLoaded, setisLoaded] = useState(false)

    const [token, setToken] = useState('')

    const [page, setPage] = useState()

    const [beerInfo, setBeerInfo] = useState()

    let { id } = useParams();

    const navigate = useNavigate()

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'



    async function getBeerById(id) {
        await axios.post(url.concat('/getBeerByID'), { id: id }).then((res) => {
            setBeerInfo(res.data)
        }).catch((error) => {
            console.log(error)
        })

    }

    function onload() {
        if (id !== undefined) {
            getBeerById(id)
            console.log(beerInfo)
            if (beerInfo === 'Not Found') {
                navigate('/404')
            }
        } else {
            navigate('/404')
        }
        setisLoaded(true)
    }


    useEffect(() => {
        if (!isLoaded) { onload() }
    }, [isLoaded])

    useEffect(() => {

        if (beerInfo === undefined) {return console.log('undefined')}

        setPage(
            <div className='beer-container'>
                <div className="beer-info">
                    <div className='beer-info-img-container'><img src={beerInfo.imgLink} alt={beerInfo.productName} /></div>
                    <h2>{beerInfo.productName}</h2>
                    <p>{beerInfo.desc}</p>
                    
                </div>

                <div className="beer-stats">
                    <ul>
                            <li>Type: {beerInfo.type}</li>
                            <li>Alcoholpercentage: {beerInfo.alcohol}%</li>
                            <li>Smaak: {beerInfo.taste}</li>
                            <li>Kleur: {beerInfo.color}</li>
                            <li>Brouwerij: {beerInfo.brewery}</li>
                            <li>Land van herkomst: {beerInfo.origin}</li>
                    </ul>
                </div>
            </div>
        )

    }, [beerInfo])


    return (
        <div className="App">
            <header>
                <div className="App-header">
                    <a className='header-logo-container' href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                    <input type="text" placeholder="Search..."></input>

                    <button className="searchBut" aria-label='icon of a magnifying glass'><i class="fa fa-search"></i></button>

                    <div className="BoozeBook"><h1>BoozeBook</h1></div>

                </div>
                <nav className='navbar'>
                    <Link className='firstNavButton' to={'/bieren'}><h2>Bieren</h2></Link>
                    <Link><h2>Sterke dranken</h2></Link>
                    <Link><h2>Café kompas</h2></Link>
                    <Link to={'/bartikeltjes'}><h2>(B)artikeltjes</h2></Link>
                    <Link to={'/developers-keuze'} className='lastNavButton'><h2>Developer's keuze</h2></Link>
                </nav>

            </header>
            <main>
                {page}
            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default Bieren