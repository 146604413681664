import React from 'react'
import { Link } from 'react-router-dom';


import './index.css';
import './App.css'
import './Account.css'

import { useNavigate } from 'react-router-dom'

function Account() {

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    const navigate = useNavigate()

    const logout = () => {
        sessionStorage.setItem('token', '');
        navigate('/')
    }

    return (

        <div className="App">
            <header className="App-header">

                <a className='header-logo-container' href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                <div className="BoozeBook"><h1>BoozeBook</h1></div>

            </header>

            <main>
                <Link className='favButton' to={'/favorites'}>Favorieten</Link>
                <button className='logoutButton' onClick={logout}>Log out</button>
            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default Account