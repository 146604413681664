import React, { useState } from 'react'
import { Navigate } from "react-router-dom";
import axios from 'axios';
import './index.css';
import './Login.css';
import './App.css'


import { useNavigate } from 'react-router-dom'

 

function Register() {

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [password2Error, setPassword2Error] = useState('')

    const navigate = useNavigate()

    const registerCall = (em, pw) => {
        axios.post(url.concat('/register'), {
                email: em,
                password: pw}).then((data) => {
            if (data.data === "Registered") {
                navigate('/login/')
            } else if (data.data === "Email already exists") {
                setEmailError("Email already exists")
            }
        })
    }  

    const register = () => {
        // Set initial error values to empty
        setEmailError('')
        setPasswordError('')

        // Check if the user has entered both fields correctly
        if ('' === email) {
            setEmailError('Please enter your email')
            return
        } 
        
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Please enter a valid email')
            return
        }
       

        if ('' === password) {
            setPasswordError('Please enter a password')
            return  
        } 

        if (password.length < 7) {
            setPasswordError('The password must be 8 characters or longer')
            return
        }

        if (password !== password2) {
            setPassword2Error('The passwords do not match')
            return
        }

        registerCall(email, password)
    }

function Login() {
    navigate('/login/')
} 

    return (

        <div className="App">
            <header className="App-header">

            <a href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                <div className="BoozeBook"><h1>BoozeBook</h1></div>

            </header>

            <main>
                <br />
                <div className={'inputContainer'}>
                    <input
                        value={email}
                        placeholder="Enter your email here"
                        onChange={(ev) => setEmail(ev.target.value)}
                        className={'inputBox'}
                    />
                    <label className="errorLabel">{emailError}</label>
                </div>
                <br />
                <div className={'inputContainer'}>
                    <input
                        value={password}
                        placeholder="Enter your password here"
                        onChange={(ev) => setPassword(ev.target.value)}
                        className={'inputBox'}
                        type='password'
                    />
                    <label className="errorLabel">{passwordError}</label>
                </div>
                <br />
                <div className={'inputContainer'}>
                    <input
                        value={password2}
                        placeholder="Repeat your password here"
                        onChange={(ev) => setPassword2(ev.target.value)}
                        className={'inputBox'}  
                        type='password'
                    />
                    <label className="errorLabel">{password2Error}</label>
                </div>
                <br />
                <div className={'buttonContainer'}>
                <div className={'inputContainer'}>
                    <input className={'inputButton'} type="button" onClick={register} value={'Register'} />
                </div>
                <div className={'inputContainer'}>
                    <input className={'inputButton'} type="button" onClick={Login} value={'Log in'} />
                </div>
                </div>
            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default Register    