import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import Register from './Register'
import Account from './Account'
import Favorites from './Favorites';
import Verify from './verify';
import Bartikeltjes from './Bartikeltjes';
import Bieren from './Bieren';
import Bier from './Bier';
import Developer from './Developers';


import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/account",
    element: <Account />
  },
  {
    path: "/verify/",
    element: <Verify />
  },
  {
    path: "/favorites",
    element: <Favorites />
  },
  {
    path: "/bartikeltjes",
    element: <Bartikeltjes />
  },
  {
    path: "/bieren",
    element: <Bieren />
  },
  {
    path: "/bier/:id",
    element: <Bier />
  },
  {
    path: "/developers-keuze",
    element: <Developer />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
