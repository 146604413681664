import React, { useState } from 'react'

import axios from 'axios';
import './index.css';
import './Login.css';
import './App.css'


import { useNavigate } from 'react-router-dom'



function Login() {

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const navigate = useNavigate()

    const loginCall = (em, pw) => {
        axios.post(url.concat('/login'), {
            email: em,
            password: pw
        }).then((data) => {

            if (data.data === 'Wrong password' && data.data !== 'Uknown email' && data.data !== 'Error' && data.data !== null) {
                setPasswordError('Wrong password')
            } else if (data.data === 'Uknown email') { 
                setEmailError('Uknown email')
            } else if (data.data === 'error' || data.data === null) {} else {
                sessionStorage.setItem('token', data.data);
                navigate('/')
        }})
    }

    const login = () => {
        // Set initial error values to empty
        setEmailError('')
        setPasswordError('')

        // Check if the user has entered both fields correctly
        if ('' === email) {
            setEmailError('Please enter your email')
            return
        }

        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError('Please enter a valid email')
            return
        }


        if ('' === password) {
            setPasswordError('Please enter a password')
            return
        }

        if (password.length < 7) {
            setPasswordError('The password must be 8 characters or longer')
            return
        }

        loginCall(email, password)
    }

    function Register() {
        navigate('/register/')
    }

    return (

        <div className="App">
            <header >

                <div className="App-header">

                <a class='header-logo-container' href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo" /></a>

                <div className="BoozeBook"><h1>BoozeBook</h1></div>

                </div>

            </header>

            <main>
                <br />
                <div className={'inputContainer'}>
                    <input
                        value={email}
                        placeholder="Enter your email here"
                        onChange={(ev) => setEmail(ev.target.value)}
                        className={'inputBox'}
                    />
                    <label className="errorLabel">{emailError}</label>
                </div>
                <br />
                <div className={'inputContainer'}>
                    <input
                        value={password}
                        type='password'
                        placeholder="Enter your password here"
                        onChange={(ev) => setPassword(ev.target.value)}
                        className={'inputBox'}
                    />
                    <label className="errorLabel">{passwordError}</label>
                </div>
                <br />
                <div className={'buttonContainer'}>


                    <div className={'inputContainer'}>
                        <input className={'inputButton'} type="button" onClick={login} value={'Log in'} />
                    </div>
                    <div className={'inputContainer'}>
                        <input className={'inputButton'} type="button" onClick={Register} value={'Register'} />
                    </div>
                </div>
            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default Login    